import { Bar, Line } from "react-chartjs-2";
import pageLoaderImage from "../../assets/images/bouncing-circles.svg";
import { getAnalysisCountService, getDashboardCountForUserService, getActiveUserByCityService, getActiveUserByMonthsService } from "../../services/userService";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        position: "bottom",
        text: "Year - 2022",
      },
      legend: {
        display: false,
      },
    },
  };

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
const formattedYesterday = yesterday.toISOString().split('T')[0];
const UserAnalytics = () => {
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(`${currentYear}`);
    const [chartData, setChartData] = useState();
    const [userChartData, setUserChartData] = useState({});
    const [data, setData] = useState();
    const [list, setList] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [activeUserByCity, setActiveUserByCity] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minLoader, setMinLoader] = useState(false);

    const getActiveUserByCity = async () => {
      setMinLoader(true);
      const query = `?startDate=${startDate}&endDate=${endDate}`;
      const res = await getActiveUserByCityService(query);
      if (res.data.statusCode) {
          const result = res.data.responseData.result;
          setActiveUserByCity(result);
          setFilteredCities(result); // Set initial filtered cities
      }
      setMinLoader(false);
    };

    useEffect(() => {
      getActiveUserByCity();
    }, []);

    const handleDateSubmit = () => {
      if (startDate && endDate) {
        getActiveUserByCity();
      }
    };

    const handleSearch = (e) => {
      const term = e.target.value.toLowerCase();
      setSearchTerm(term);
      setFilteredCities(activeUserByCity.filter(city => city.city.toLowerCase().includes(term)));
    };

    const analysisCount = () => {
        getAnalysisCountService().then((res) => {
            if (res.data.statusCode) {
                const { user, chef, product, recipe, SO } = res.data.responseData.result;
                const _data = { user, chef, product, recipe, SO };
                setData(res.data.responseData.result);
                let label = Object.keys(_data);
                label.pop();
                let value = Object.values(_data);
                const labels = ["BAKERS", "CHEFS", "PRODUCTS", "RECIPES", "SO"];
                
                let data = {
                    labels: labels,
                    datasets: [
                      {
                        data: value,
                        backgroundColor: [
                          "#FFBB00",
                          "#7F00A6",
                          "#00A606",
                          "#EF5D5D",
                          "#61AFDC",
                          "#9FC545",
                        ],
                        borderRadius: 4,
                      },
                    ],
                  };
                  setChartData(data);
                  setPageLoader(false);
            }
        });
    };

    const getList = () => {
        getDashboardCountForUserService().then((res) => {
            setList(res.data.responseData.result.login);
            setPageLoader(false);
        });
    };

    useEffect(() => {
        analysisCount();
        getList();
    }, []);



    const getActiveUserByMonths = async () => {
        const query = `?year=${selectedYear}`;
        try {
            const res = await getActiveUserByMonthsService(query);
            
            if (res.data.statusCode) {
                const _data = res.data.responseData.result;
                const chartData = new Array(12).fill(0); 
                _data.forEach((item) => {
                    const month = item.month;
                    const usersCount = item.users;
                    if (month >= 1 && month <= 12) {
                        chartData[month - 1] = usersCount;
                    }
                });
    
                setUserChartData({
                    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    datasets: [
                        {
                            label: "Users",
                            data: chartData,
                            borderColor: "rgb(1 51 160)",
                            tension: 0
                        }
                    ]
                });
            }
        } catch (error) {
            console.error("Error fetching active users by months:", error);
        }
    };
    
    

    useEffect(() => {
        getActiveUserByMonths();
        
    }, [selectedYear])


    const handleDateChangeFilter = date => {
        const _currentYear = new Date(date).getFullYear();
        setSelectedYear(`${_currentYear}`)
    }



    if (pageLoader) {
        return (
            <div className="page_loader"><img src={pageLoaderImage} alt="Loading..." /></div>
        );
    } else {
        return (
            <div className="dash_section mt-5">
                <div className="row">
                    <div className="col-lg-8 mb-4">
                        <div className="chart_container">
                            <h5>All User Types</h5>
                            <hr />
                            <div className="bar">
                                {chartData ? <Bar data={chartData} options={options} /> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="total_card">
                            <h5>Total Bakers</h5>
                            <h2>{data?.user}</h2>
                        </div>
                        <div className="total_card">
                            <h5>Total Chef</h5>
                            <h2>{data?.chef}</h2>
                        </div>
                        <div className="total_card">
                            <h5>Total Product</h5>
                            <h2>{data?.product}</h2>
                        </div>
                        <div className="total_card">
                            <h5>Total Recipe</h5>
                            <h2>{data?.recipe}</h2>
                        </div>
                        <div className="total_card">
                            <h5>Total SO</h5>
                            <h2>{data?.SO}</h2>
                        </div>
                    </div>
                </div>


                <div className="user_line_chart">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>Users</h5>
                        <div className="form-group">
                            <label className="me-3">Select Year: </label>
                            <DatePicker
                                selected={selectedYear}
                                onChange={handleDateChangeFilter}
                                showYearPicker
                                dateFormat="yyyy"
                                className="form-control"
                                maxDate={new Date()}
                                placeholderText="Select Year"
                            />
                        </div>
                    </div>
                    {userChartData?.labels?.length ? <Line data={userChartData} /> : ''}
                    
                </div>

                <div className="row">
                    <div className="col-lg-7">
                        <div>
                            <h5 className="mt-5">Average Customer Lifespan</h5>
                            <table className="table table-responsive">
                                <thead>
                                    <tr className="medium-font font-14px">
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Time</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list && list.map((item, index) => (
                                        <tr key={index} className="regular-font font-14px">
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.time}</td>
                                            <td>
                                                {item.user_role === "admin" && 'Admin'}
                                                {item.user_role === "chef" && 'Baker'}
                                                {item.user_role === "pillsbury_chef" && 'Pillsbury Chef'}
                                                {item.user_role === "store_manager" && 'Store Manager'}
                                                {item.user_role === "purchase_officer" && 'Purchase Officer'}
                                            </td>
                                        </tr>
                                    ))}
                                    {!list.length && <tr><td colSpan={4} className="no_table">No Data</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="user_city_card">
                            <h5 className="mt-5">Active Users By City</h5>
                            <div className="d-flex align-items-center mt-2 mb-3">
                                <input placeholder="Start Date" value={startDate} onChange={e => setStartDate(e.target.value)} type="date" className="form-control w-auto me-2" />
                                <input placeholder="End Date" value={endDate} onChange={e => setEndDate(e.target.value)} type="date" className="form-control w-auto me-2" max={formattedYesterday} />
                                {minLoader ? <span>Loading...</span> : <span className="btn btn-primary" onClick={handleDateSubmit}>Submit</span>}
                            </div>

                           

                            <div className="city_card_wrapper">
                            <input placeholder="Search City" value={searchTerm} onChange={handleSearch} type="text" className="form-control w-100 mb-3" />
                                {filteredCities.map((item, index) => {
                                    const maxUsers = Math.max(...filteredCities.map(user => user.users));
                                    const widthPercentage = `${Math.min((item.users / maxUsers) * 100, 100)}%`;
                                    return (
                                        <div key={index} className="city_card">
                                            <div className="city_name">{item.city}</div>
                                            <div className="city_count">{item.users}</div>
                                            <div className="city_user_line">
                                                <div style={{ width: widthPercentage }} className="city_user_line_active" />
                                            </div>
                                        </div>
                                    );
                                })}
                                {!filteredCities.length && <div className="no_table">No Data</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserAnalytics;
